import T from "@redwit-commons/utils/typecheck";
import { PDFBuildOptions, PDFBuildOptionsSchema } from "../object/taskv2/pdf";
import { SearchParams, searchParamsSchema } from "./common";

export interface IRequestFolderParam {
  folderId?: string;
}

export interface IExcludedNoteList {
  exclude?: string[];
}

const IExcludedNoteListSchema = T.object().addField(
  "exclude",
  T.array(T.string()),
  false
);

export interface IAdvancedSearchParam extends SearchParams {
  orderBy?: "createdAt" | "writtenAt";
  // 작성자 목록 (separator '|' 사용해서 OR 연결)
  authors?: string;
  // 확장자 목록 (separator '|' 사용해서 OR 연결)
  extensions?: string;
  // 태그 검색 목록 (separator '|' 사용해서 OR 연결)
  tags?: string;
  // OCR 검색 텍스트
  ocrText?: string;
  fileName?: string;
  limit?: number;
  offset?: number;
}

const IAdvancedSearchParamSchema = searchParamsSchema
  .clone()
  .addField("orderBy", T.string().withEnum(["createdAt", "writtenAt"]), false)
  .addField("authors", T.string(), false)
  .addField("extensions", T.string(), false)
  .addField("tags", T.string(), false)
  .addField("ocrText", T.string(), false)
  .addField("fileName", T.string(), false)
  .addField("limit", T.integer(), false)
  .addField("offset", T.integer(), false);

export interface IGetNotes extends IAdvancedSearchParam, IRequestFolderParam {}

export const IGetNotesSchema = IAdvancedSearchParamSchema.clone().addField(
  "folderId",
  T.string(),
  false
);

export const validateIGetNotes = T.mkValidator<IGetNotes>(IGetNotesSchema);

/**
 * Sharp 에서 input 으로 받을 수 있는 이미지인지 확인한다.
 */
export type ImageExtension = "jpg" | "jpeg" | "png" | "svg" | "tiff" | "webp";
export const imageExtensionList = ["jpg", "jpeg", "png", "svg", "tiff", "webp"];
export const trivialImageExtensionList = ["jpg", "jpeg", "png"];
export const ImageExtensionSchema = T.string().withEnum(imageExtensionList);
export const validateImageExtension =
  T.mkValidator<ImageExtension>(ImageExtensionSchema);

/**
 * heic-convert 에서 처리 가능한 이미지 타입을 말한다
 */
export type HEICImageExtension = "heif" | "heic";
export const heicExtensionList = ["heif", "heic"];
export const HEICImageExtensionSchema = T.string().withEnum(heicExtensionList);
export const validateHEICImageExtension = T.mkValidator<HEICImageExtension>(
  HEICImageExtensionSchema
);

/**
 * Openoffice 에서 pdf로 변환 가능한 타입을 말한다.
 */
export type OfficeFileExtension =
  | "doc"
  | "docx"
  | "ppt"
  | "pptx"
  | "xls"
  | "xlsx";
export const officeExtensionList = [
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
];
export const OfficeFileExtensionSchema =
  T.string().withEnum(officeExtensionList);
export const validateOfficeFileExtension = T.mkValidator<OfficeFileExtension>(
  OfficeFileExtensionSchema
);

export type PDFFileExtension = "pdf";
export const pdfExtensionList = ["pdf"];
export const PDFFileExtensionSchema = T.string().withEnum(pdfExtensionList);
export const validatePDFFileExtension = T.mkValidator<PDFFileExtension>(
  PDFFileExtensionSchema
);

export type UnknownExtension = "unknown";
export const unknownExtensionList = ["unknown"];
export const UnknownExtensionSchema = T.string().withEnum(pdfExtensionList);
export const validateUnknownExtension = T.mkValidator<UnknownExtension>(
  UnknownExtensionSchema
);
/**@deprecated */
export type HWPFileExtension = "hwp";
/**@deprecated */
export const hwpExtensionList = ["hwp"];
/**@deprecated */
export const HWPFileExtensionSchema = T.string().withEnum(hwpExtensionList);
/**@deprecated */
export const validateHWPFileExtension = T.mkValidator<HWPFileExtension>(
  HWPFileExtensionSchema
);

export type ProprietaryExtension =
  // | "hwp"
  "doc" | "docx" | "xls" | "xlsx" | "ppt" | "pptx";
export const proprietaryExtensionList = [
  // "hwp",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
];
export const ProprietaryExtensionSchema = T.string().withEnum(
  proprietaryExtensionList
);
export const validateProprietaryExtension = T.mkValidator<ProprietaryExtension>(
  ProprietaryExtensionSchema
);

export type ICreateNote = {
  cid: string;
  /**
   * 반드시 소문자로 넣어주세요 "pdf", "png" 처럼 '.' 빼고 주세요.
   *
   * 이 필드 변경 시 서버측에서 IPFS 업로드 가능 리스트에도 넣어주어야 합니다.
   */
  extension:
    | ImageExtension
    | HEICImageExtension
    | OfficeFileExtension
    | PDFFileExtension
    | UnknownExtension;
  tag?: Array<string>;
  writtenAt?: string;
  CipherId?: string;
  fileName?: string;
} & IRequestFolderParam;

export const ICreateNoteSchema = T.object()
  .addField("cid", T.string())
  .addField(
    "extension",
    T.string().withEnum([
      ...imageExtensionList,
      ...heicExtensionList,
      ...officeExtensionList,
      ...pdfExtensionList,
      ...unknownExtensionList,
    ])
  )
  .addField("tag", T.array(T.string()), false)
  .addField("writtenAt", T.string(), false)
  .addField("CipherId", T.string(), false)
  .addField("fileName", T.string(), false)
  .addField("folderId", T.string(), false);

export const validateICreateNote =
  T.mkValidator<ICreateNote>(ICreateNoteSchema);

export interface IUpdateNote {
  writtenAt?: string;
  fileName?: string;
}

export const IUpdateNoteSchema = T.object()
  .addField("writtenAt", T.string(), false)
  .addField("fileName", T.string(), false);

export const validateIUpdateNote =
  T.mkValidator<IUpdateNote>(IUpdateNoteSchema);

export type IGetAuthNotes = SearchParams;

export const IGetAuthNotesSchema = searchParamsSchema.clone();

export const validateIGetAuthNotes =
  T.mkValidator<IGetAuthNotes>(IGetAuthNotesSchema);

export type ISearch = {
  q: string;
} & SearchParams;

export const ISearchSchema = searchParamsSchema
  .clone()
  .addField("q", T.string());

export const validateISearch = T.mkValidator<ISearch>(ISearchSchema);

export interface IPostEmailPDF {
  pdf_path: string; // pdf ipfs url
  email: string;
  file_name?: string;
}

export const IPostEmailPDFSchema = T.object()
  .addField("pdf_path", T.string())
  .addField("file_name", T.string(), false)
  .addField("email", T.string());

export const validateIPostEmailPDF =
  T.mkValidator<IPostEmailPDF>(IPostEmailPDFSchema);

export interface IGetNotePDF {
  noteIds: string[];
  folderIds?: string[];
  fileName?: string;
  pdfOpt?: PDFBuildOptions;
}

export const IGetNotePDFSchema = T.object()
  .addField("noteIds", T.array(T.string()))
  .addField("folderIds", T.array(T.string()), false)
  .addField("fileName", T.string(), false)
  .addField("pdfOpt", PDFBuildOptionsSchema.clone(), false);

export const validateIGetNotePDF =
  T.mkValidator<IGetNotePDF>(IGetNotePDFSchema);

export interface IGetAllNotePDF extends IExcludedNoteList {
  folderIds?: string[];
  fileName?: string;
  pdfOpt?: PDFBuildOptions;
  advancedSearch?: IAdvancedSearchParam;
}

export const IGetAllNotePDFSchema = IExcludedNoteListSchema.clone()
  .addField("folderIds", T.array(T.string()), false)
  .addField("fileName", T.string(), false)
  .addField("pdfOpt", PDFBuildOptionsSchema.clone(), false)
  .addField("advancedSearch", IAdvancedSearchParamSchema.clone(), false);

export const validateIGetAllNotePDF =
  T.mkValidator<IGetAllNotePDF>(IGetAllNotePDFSchema);
T.mkValidator<IPostEmailPDF>(IPostEmailPDFSchema);

export interface IDeleteAllNotes extends IExcludedNoteList {
  advancedSearch?: IAdvancedSearchParam;
}
export const IDeleteAllNotesSchema = IExcludedNoteListSchema.clone().addField(
  "advancedSearch",
  IAdvancedSearchParamSchema.clone(),
  false
);

export const validateIDeleteAllNotes = T.mkValidator<IDeleteAllNotes>(
  IDeleteAllNotesSchema
);

export interface IMoveNote {
  projectId: string;
  folderId?: string;
}

export const IMoveNoteSchema = T.object()
  .addField("projectId", T.string())
  .addField("folderId", T.string(), false);

export const validateIMoveNote = T.mkValidator<IMoveNote>(IMoveNoteSchema);

export interface IMoveAllNotes extends IExcludedNoteList {
  from: IMoveNote;
  to: IMoveNote;
  advancedSearch?: IAdvancedSearchParam;
}

export const IMoveAllNotesSchema = IExcludedNoteListSchema.clone()
  .addField("from", IMoveNoteSchema.clone())
  .addField("to", IMoveNoteSchema.clone())
  .addField("advancedSearch", IAdvancedSearchParamSchema.clone(), false);

export const validateIMoveAllNotes =
  T.mkValidator<IMoveAllNotes>(IMoveAllNotesSchema);
export interface IUpdateNoteOrderPrefix {
  orderBy?: "createdAt" | "writtenAt";
  reverse?: boolean;
}

export const IUpdateNoteOrderPrefixSchema = T.object()
  .addField("orderBy", T.string().withEnum(["createdAt", "writtenAt"]), false)
  .addField("reverse", T.boolean(), false);

export const validateIUpdateNoteOrderPrefix =
  T.mkValidator<IUpdateNoteOrderPrefix>(IUpdateNoteOrderPrefixSchema);
